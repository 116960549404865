import * as React from 'react';
import Art from '../../static/artdiario.svg';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { Link } from 'gatsby';
import dayjs from 'dayjs';
const year = dayjs().format('YYYY');

const Footer = () => {
  return (
    <>
      <img
        className="mx-auto mt-40"
        src={Art}
        alt="Art Diario"
        data-sal-duration="400"
        data-sal="slide-up"
        data-sal-delay="500"
        data-sal-easing="ease-in-out"
      />
      <p className="mb-20 mt-10 text-center text-slate-400">
        <Link to="/art" className="text-blue-600 hover:underline">
          Previous Art
        </Link>
      </p>
      <p className="mb-20 mt-10 text-center text-slate-400">
        © {year}{' '}
        <OutboundLink
          href="https://www.jimmyhooker.com/"
          className="text-blue-600 hover:underline"
        >
          Jimmy Hooker
        </OutboundLink>
        , All rights reserved.
      </p>
    </>
  );
};

export default Footer;
